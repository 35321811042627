import {
  differenceInDays,
  format,
  isBefore,
  parse,
  parseISO,
  setHours,
  setMinutes,
  setSeconds,
} from 'date-fns';

export function getDateFormat(date: number | Date, withTime = true): string {
  try {
    return format(date, `dd/MM/yyyy${withTime ? ' HH:mm:ss' : ''}`);
  } catch (_) {
    return null;
  }
}

export function getDateNow(): string {
  return format(new Date(), 'dd/MM/yyyy HH:mm:ss');
}

export function getDateUsingFormat(date: Date, formatString: string): string {
  return format(date, formatString);
}

export function formatDate(date: string, withTime = true): string {
  return getDateFormat(getDateFromString(date), withTime);
}

export function convertToDates(dates: (Date | string)[]): Date[] {
  return dates?.map((date) => (!(date instanceof Date) ? parseISO(date) : date));
}

export function getOldestDate(dates: (Date | string)[]): Date {
  const castedDates = convertToDates(dates);
  return castedDates.length === 1
    ? castedDates[0]
    : castedDates.reduce((c, n) => (isBefore(n, c) ? n : c));
}

export function getDateFromString(date: string): Date {
  return new Date(date);
}

export function parseToValidDate(date: string | Date, format = 'dd/MM/yyyy'): Date {
  if (date instanceof Date) {
    return date;
  }

  // Try parsing with the provided format
  let parsedDate = parse(date, format, new Date());

  // If parsing fails, try ISO format
  if (isNaN(parsedDate.getTime())) {
    parsedDate = parseISO(date);
  }

  // If still invalid, return a fallback date (to avoid crashing)
  return isNaN(parsedDate.getTime()) ? null : parsedDate;
}

export function addTimeToDate(date: Date): Date {
  if (!date) return null;

  const now = new Date();

  return setSeconds(setMinutes(setHours(date, now.getHours()), now.getMinutes()), now.getSeconds());
}

export function stringDateComparator(firstDate: string, secondDate: string) {
  const castFirstDate = ![null, undefined, '-'].includes(firstDate)
    ? new Date(firstDate.replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$3/$2/$1'))
    : new Date(0);
  const castSecondDate = ![null, undefined, '-'].includes(secondDate)
    ? new Date(secondDate.replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$3/$2/$1'))
    : new Date(0);
  castFirstDate.setHours(0, 0, 0, 0);
  castSecondDate.setHours(0, 0, 0, 0);
  return castFirstDate.getTime() - castSecondDate.getTime();
}

export function getDifferenceInDays(startDate: Date, endDate: Date): number {
  startDate.setHours(0, 0, 0, 0);
  endDate.setHours(0, 0, 0, 0);
  const days = differenceInDays(endDate, startDate);
  // Add 1 to include the start date
  return days + 1;
}

export function getJobTitle(): string {
  const date = new Date();
  const year = date.getFullYear();

  const month = date.getMonth();

  let quarter: string;
  if (month >= 0 && month <= 2) {
    quarter = 'Q1';
  } else if (month >= 3 && month <= 5) {
    quarter = 'Q2';
  } else if (month >= 6 && month <= 8) {
    quarter = 'Q3';
  } else {
    quarter = 'Q4';
  }

  // Generate the title
  return `Create a Job - ${quarter} ${year}`;
}

export function getLastThreeYearsQuarters(): string[] {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentQuarter = Math.ceil((currentDate.getMonth() + 1) / 3);

  const quarters: string[] = [];

  for (let year = currentYear; year >= currentYear - 3; year--) {
    for (let quarter = 4; quarter >= 1; quarter--) {
      if (year === currentYear && quarter > currentQuarter) continue; // Skip future quarters
      quarters.push(`${year}-Q${quarter}`);
    }
  }

  return quarters;
}

/**
 * Adds exactly six calendar months to a given start date.
 * Handles end-of-month edge cases and leap years.
 * Example: 2025-08-31 → 2026-02-28 (Feb has no 31st)
 * @param startDate - The starting Date object
 * @returns A new Date object six months later
 */
export function addSixMonths(startDate: Date): Date {
  const year = startDate.getFullYear();
  const month = startDate.getMonth(); // 0-based
  const day = startDate.getDate();

  // Target month/year after adding 6 months
  const targetMonth = month + 6;
  const targetDate = new Date(year, targetMonth, 1); // Temporarily set to day 1

  // Get number of days in the target month
  const daysInTargetMonth = new Date(
    targetDate.getFullYear(),
    targetDate.getMonth() + 1,
    0
  ).getDate();

  // Use the same day, or clamp to the last day of target month if needed
  const finalDay = Math.min(day, daysInTargetMonth);

  return new Date(targetDate.getFullYear(), targetDate.getMonth(), finalDay);
}

import { Typo } from '@digital-at-vallourec/steel-design-system-react';
import { zodResolver } from '@hookform/resolvers/zod';
import { Close } from '@mui/icons-material';
import { Grid } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { HttpStatusCode } from 'axios';
import { TFunction } from 'i18next';
import { useSnackbar } from 'notistack';
import { useEffect } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Swal from 'sweetalert2';
import { TypeOf, object, string } from 'zod';

import SwitchIcon from '../../../assets/icons/icon-switch.svg';
import { useAppSelector } from '../../../hooks';
import { LabelValue } from '../../../interfaces/label-value';
import { ChangeStatusApi, Status } from '../../../interfaces/veg';
import { saveNewInvoice } from '../../../services/api/invoice-api';
import { updateSetStatus } from '../../../services/api/veg-api';
import { selectUser, selectUserId } from '../../../services/store/userSlice';
import { $primaryNavy } from '../../../styles/colors';
import { BookingStatus, SetAvailabilityStatus } from '../../../utils';
import { REQUIRED_FIELD } from '../../../utils/constants/validators-message';
import { getErrorResponseData } from '../../../utils/functions';
import { CloseIconButton, FormLabelField, FormSelect, FormTextField } from '../../shared';
import { mapGpsInvoice } from './utils';

interface ChangeStatusProps {
  id: number;
  currentStatus: Status;
  initialTargetStatusList: LabelValue[];
  openChangeStatusDialog: boolean;
  setChangeStatusDialog: Function;
  submitUpdateStatus: Function;
  licensee_number: number;
  updateActualStatus?: Function;
  translation?: TFunction;
  replaced_booking_id?: number;
  set_id?: number;
  isSetDetailPage?: boolean;
}

const formSchema = object({
  current_status: string().min(1, { message: REQUIRED_FIELD }),
  target_status: string().min(1, { message: REQUIRED_FIELD }),
  comments: string().optional(),
});

type FormSchemaType = TypeOf<typeof formSchema>;

const defaultValues: FormSchemaType = {
  current_status: '',
  target_status: '',
  comments: '',
};

export function DialogChangeStatus({
  id,
  currentStatus,
  openChangeStatusDialog,
  setChangeStatusDialog,
  updateActualStatus,
  initialTargetStatusList,
  translation,
  submitUpdateStatus,
  licensee_number,
  replaced_booking_id,
  set_id,
  isSetDetailPage,
}: ChangeStatusProps) {
  const { t: tCommon } = useTranslation('common');
  const { enqueueSnackbar } = useSnackbar();
  const userId = useAppSelector(selectUserId);
  const { firstName, lastName } = useAppSelector(selectUser);

  const methods = useForm<FormSchemaType>({
    defaultValues: defaultValues,
    mode: 'onChange',
    resolver: zodResolver(formSchema),
  });
  const { handleSubmit, reset, setValue } = methods;

  useEffect(() => {
    setValue('current_status', translation(`status.${currentStatus}`));
  }, [openChangeStatusDialog]);

  /* istanbul ignore next */
  const handleUpdateBookingStatus = (dataApi) => {
    submitUpdateStatus(dataApi)
      .then(() => {
        // If Booking status goes to Delivered, then update Set status to AtLicensee
        // Automatic with scheduler but not when done manually
        if (dataApi.target_status === BookingStatus.Delivered && set_id) {
          updateSetStatus({
            ...dataApi,
            id: set_id,
            current_status: SetAvailabilityStatus.Validated,
            target_status: SetAvailabilityStatus.AtLicensee,
          });
        }
        enqueueSnackbar(tCommon('updateStatusOk'), {
          preventDuplicate: true,
          variant: 'success',
        });
        updateActualStatus(dataApi.target_status);
        handleClose();
      })
      .catch(function (error) {
        console.error(error);
        if (error?.response?.status === HttpStatusCode.Unauthorized) {
          handleClose();
          Swal.fire({
            title: 'Unauthorized',
            text: "You don't have permission to update a booking status",
            icon: 'error',
          });
        } else if (error?.response?.status === HttpStatusCode.Conflict) {
          handleClose();
          Swal.fire({
            title: 'Conflict error',
            text: isSetDetailPage
              ? `The setId ${
                  id || set_id
                } is already reserved in the reservation with the business number ${
                  getErrorResponseData(error)?.business_number
                }`
              : '',
            icon: 'error',
          });
        } else {
          enqueueSnackbar(tCommon('updateStatusFailed'), {
            preventDuplicate: true,
            variant: 'error',
          });
        }
      });
  };

  /* istanbul ignore next */
  const onSubmit: SubmitHandler<FormSchemaType> = ({ target_status, comments }) => {
    const dataApi: ChangeStatusApi = {
      id,
      current_status: currentStatus as Status,
      target_status: target_status as Status,
      comments,
      user_id: userId,
      licensee_number,
      modification_date: new Date(),
      replaced_booking_id,
    };
    if (target_status === BookingStatus.Gps) {
      const gpsData = mapGpsInvoice(dataApi, firstName, lastName);
      saveNewInvoice(gpsData)
        .then(() => {
          handleUpdateBookingStatus(dataApi);
        })
        .catch(function (error) {
          console.error(error);
          enqueueSnackbar(tCommon('updateStatusFailed'), {
            preventDuplicate: true,
            variant: 'error',
          });
        });
    } else {
      handleUpdateBookingStatus(dataApi);
    }
  };

  /* istanbul ignore next */
  const handleClose = () => {
    reset();
    setChangeStatusDialog(false);
  };

  return (
    <Dialog open={openChangeStatusDialog} onClose={handleClose} fullWidth={true} maxWidth="md">
      <CloseIconButton data-testid="dialog-close-button" onClick={handleClose}>
        <Close />
      </CloseIconButton>

      <DialogTitle>
        <div className="tw-flex tw-flex-col tw-text-center">
          <div className="tw-pt-2">
            <img src={SwitchIcon} alt="support-switch-status-icon" />
          </div>
          <div>
            <Typo variant="body1" color={$primaryNavy} fontWeight={500}>
              {translation('title')}
            </Typo>
            <Typo variant="subtitle4">{translation('subtitle')}</Typo>
          </div>
        </div>
      </DialogTitle>

      <DialogContent>
        <Grid container rowSpacing={3} columnSpacing={{ xs: 2, sm: 3, md: 4 }} className="tw-px-16">
          <FormProvider {...methods}>
            <Grid item xs={12}>
              <FormLabelField
                id="select_current_status"
                data-testid="select_current_status"
                name="current_status"
                label={`${translation('currentStatus')}`}
              />
            </Grid>
            <Grid item xs={12}>
              <FormSelect
                id="select_target_status"
                variant="standard"
                data-testid="select_target_status"
                name="target_status"
                label={`${translation('targetStatus')} *`}
                options={initialTargetStatusList}
                nsTranslate="gauge"
              />
            </Grid>
            <Grid item xs={12}>
              <FormTextField
                sx={{
                  width: '100%',
                }}
                id="comments-multiline-static"
                name="comments"
                label={translation('note')}
                multiline
                rows={4}
                placeholder={translation('notePlaceholder')}
                variant="outlined"
                data-testid="change-status-comment"
              />
            </Grid>
          </FormProvider>
        </Grid>
      </DialogContent>

      <DialogActions className="!tw-justify-center">
        <Button
          data-testid="confirm-change-status-btn-submit"
          variant="cta"
          type="submit"
          onClick={handleSubmit(onSubmit)}
        >
          {translation('confirmModificationCta')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
